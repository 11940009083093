import { extendTheme, ThemeConfig, theme as base, withDefaultVariant } from '@chakra-ui/react'
import '@fontsource/inter/400.css'
import '@fontsource/inter/700.css'

const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: true,
}

const fonts = {
    ...base.fonts,
    body: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    heading: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
}

export const theme = extendTheme(
    {
        config,
        fonts: fonts,
    },
    withDefaultVariant({
        variant: 'outline',
        components: ['Button', 'IconButton'],
    })
)

// // importing the required chakra libraries
// import { theme as chakraTheme } from '@chakra-ui/react'
// import { extendTheme } from "@chakra-ui/react"

// // declare a variable for fonts and set our fonts. I am using Inter with various backups but you can use `Times New Roman`. Note we can set different fonts for the body and heading.
// const fonts = {
//   ...chakraTheme.fonts,
//   body: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
//   heading: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`
// }

// // declare a variable for our theme and pass our overrides in the e`xtendTheme` method from chakra
// const customTheme = extendTheme(overrides)

// // export our theme
// export default customTheme
